<template>
<div class="landlordDashboardContainer">
  <Navbar></Navbar>
    <v-layout justify-center wrap>
      <v-flex xs12 sm10 md9>

    <Loading v-if="initialLoad"></Loading>
    <v-layout  wrap v-else>
      <v-flex xs12 py-2>
        <v-layout elevation-2 align-center ml-4 py-3  wrap>
          <v-flex xs2 sm1 class="text-xs-center pr-2 title" >
            <v-icon color="primary">remove_red_eye</v-icon>
          </v-flex>
          <v-flex xs10 sm11 class="title">
            # listing views in the last 30 days: {{monthViews}}
          </v-flex>
        </v-layout>

      </v-flex>
      <v-flex xs12>
        <v-layout  justify-center wrap>

          <div class="display-3">
            {{$t('landlordDashboard.apartmentsTitle')}}
          </div>
        </v-layout>

          <v-layout justify-center wrap v-if="apartments.length>0 || wholeApts.length>0">
            <draggable v-model="apartments" class="hidden-sm-and-down" @sort="setNewOrder(apartments,'apartments')">
              <DashboardApartmentCard v-for="(id) in apartments" :key="id.apartmentId" v-if="" :apartmentId="id.apartmentId" @addRoomCount="addRoomCount">
              </DashboardApartmentCard>
            </draggable>
            <div class="hidden-md-and-up" >
              <DashboardApartmentCard v-for="(id) in apartments" :key="id.apartmentId" :apartmentId="id.apartmentId">
              </DashboardApartmentCard>
            </div>
            <draggable v-model="wholeApts" class="hidden-sm-and-down" @sort="setNewOrder(wholeApts,'wholeApts')">
              <DashboardWholeAptCard v-for="(id) in wholeApts" :key="id.apartmentId" :apartmentId="id.apartmentId" :type="'wholeApt'">
              </DashboardWholeAptCard>
            </draggable>
            <div class="hidden-md-and-up" >
              <DashboardWholeAptCard v-for="(id) in wholeApts" :key="id.apartmentId" :apartmentId="id.apartmentId" :type="'wholeApt'">
              </DashboardWholeAptCard>
            </div>
          </v-layout>
          <v-layout  justify-center wrap v-else>

            <v-card>
              <v-layout  justify-center wrap>

                <v-icon large justify-center class="top-padding-only pa-3 ">domain</v-icon>
              </v-layout>
              <v-card-title primary-title>
                <div>
                  <h3 class="headline mb-0">{{$t('landlordDashboard.noAptTitle')}}</h3>
                  <div>{{$t('landlordDashboard.noAptText')}}</div>
                </div>
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" href="/addListing">+ add Apartment</v-btn>
              </v-card-actions>
            </v-card>
          </v-layout>
      </v-flex>
      <v-flex xs12>
        <v-divider></v-divider>

      </v-flex>
      <v-flex xs12>

        <v-layout  justify-center wrap>
          <div class="display-3">
            {{$t('landlordDashboard.studiosTitle')}}
          </div>
        </v-layout>
        <v-layout  wrap v-if="studios.length>0">
          <draggable v-model="studios" class="hidden-sm-and-down" @sort="setNewOrder(studios,'studios')">
            <DashboardStudioCard v-for="(id) in studios" :key="id.studioId" :studioId="id.studioId">
            </DashboardStudioCard>
          </draggable>
          <div class="hidden-md-and-up" >
            <DashboardStudioCard v-for="(id) in studios" :key="id.studioId" :studioId="id.studioId">
            </DashboardStudioCard>
          </div>
        </v-layout>
        <v-layout  wrap justify-center v-else>
          <v-card>
            <v-layout  justify-center wrap>

              <v-icon large justify-center class="top-padding-only pa-3 ">hotel</v-icon>
            </v-layout>
            <v-card-title primary-title>
              <div>
                <h3 class="headline mb-0">{{$t('landlordDashboard.noStudioTitle')}}</h3>
                <div>{{$t('landlordDashboard.noAptText')}}</div>
              </div>
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" href="/addListing">{{$t('landlordDashboard.addStudio')}}</v-btn>
            </v-card-actions>
          </v-card>

        </v-layout>
      </v-flex>
      <v-flex xs12 class="pt-3" v-if="user.acceptSummer==true">
        <v-layout  justify-center wrap>

          <div class="display-3">
            Summer Functions
          </div>
        </v-layout>
        <v-layout  wrap ma-4>
          <v-flex xs12>
            <div class="title">
              Set minimum contract length on all listings
            </div>
          </v-flex>
          <v-flex xs6>
            <v-select
              :items="contractLengthOptions"
              v-model="minLengthVal"
              :label="$t('contractDetails.minLength')"
              :hint="$t('contractDetails.minLengthHint')"
            >
            </v-select>
          </v-flex>
          <v-flex xs6 class="pl-5">

            <v-btn color="primary" @click="setAllListings">Save</v-btn>
          </v-flex>

        </v-layout>
      </v-flex>

    </v-layout>
  </v-flex>
</v-layout>
<v-snackbar
  v-model="showSnackbar"
  color="primary"
  :timeout="3000"

>
  {{snackbarMessage}}
  <v-btn flat @click.native="showErrorSnackbar = false">Close</v-btn>
</v-snackbar>
<v-snackbar
  v-model="showErrorSnackbar"
  color="error"
  :timeout="6000"

>
  {{errorSnackbarMessage}}
  <v-btn flat @click.native="showErrorSnackbar = false">Close</v-btn>
</v-snackbar>
<v-dialog
  v-model="dialogOpen"
  :overlay="false"
  max-width="400px"
  transition="dialog-transition"
>
<v-card>
  <v-card-title secondary-title>
    {{dialogText}}

  </v-card-title>

  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn color="primary" @click.native="dialogOpen=false">Close</v-btn>
  </v-card-actions>
</v-card>
</v-dialog>

</div>
</template>
<script>
import Loading from '../shared/Loading.vue'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'
import DashboardApartmentCard from './DashboardApartmentCard.vue'
import DashboardStudioCard from './DashboardStudioCard.vue'
import DashboardWholeAptCard from './DashboardWholeAptCard.vue'
import firebase from '../../FirebaseApp'
import axios from 'axios'
import moment from 'moment'
import draggable from 'vuedraggable'

export default {
  name: "",
  data: () => ({
    dialogOpen:false,
    dialogText:"Listings are being updated. This should only take a few seconds.",
    showSnackbar:false,
    snackbarMessage:"",
    showErrorSnackbar:false,
    errorSnackbarMessage:"Saving failed, please check your internet connection and try again",
    apartments: [],
    studios:[],
    wholeApts:[],
    initialLoad: true,
    minLengthVal:"",
    studioViews:0,
    roomViews:0,
    weekViews:0,
    monthViews:0,
    wholeAptsViews:0,
    totalViews:0,
  }),
  methods: {

    sortListings(a,b) {
      console.log('sorting', a, b);
      if ( a.order < b.order){
        return -1;
      }
      if ( a.order > b.order ){
        return 1;
      }
      return 0;

    },
    setNewOrder(arr, dbName){
      console.log('set order',arr);
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].order!=i){
            console.log( 'users/' + this.user.id + '/'+dbName+'/'+arr[i]['.key']+'.json?auth=');
            axios.patch('users/' + this.user.id + '/'+dbName+'/'+arr[i]['.key']+'.json?auth='+this.$store.state.auth.idToken,{order:i})
            .then(res=>{
              console.log(res, ' success');
            })
            .catch(err =>{
              console.log(err, " Error");
            })

        }
      }


    },
    addRoomCount(payload){
      console.log('roomID: ',payload[Object.keys(payload)[0]].id);
      this.roomViews=this.roomViews+Object.keys(payload).length
      this.totalViews=this.totalViews+Object.keys(payload).length
      for (var j in payload) {
        if (payload.hasOwnProperty(j)) {
          if (moment(payload[j].ts).isAfter(moment().subtract(1,'Month'))) {
            this.monthViews++
            if (moment(payload[j].ts).isAfter(moment().subtract(1,'week'))) {
              this.weekViews++
            }
          }
        }
      }

    },
    setAllListings() {
      // console.log('Set all listings');
      for (var i = 0; i < this.apartments.length; i++) {
        // console.log('apartments/'+this.apartments[i].apartmentId+'/contractDetails.json?auth=',{contractLength:this.minLengthVal});
        axios.patch('apartments/'+this.apartments[i].apartmentId+'/contractDetails.json?auth='+this.$store.state.auth.idToken,{contractLength:this.minLengthVal})
        .then(()=>{
          this.showSnackbar=false
          this.snackbarMessage="Shared Apartments Updated!"
          this.showSnackbar=true
          console.log('Finished apts');

        })
        .catch(()=>{
          this.showErrorSnackbar=true
        })
      }

      for (var j = 0; j < this.studios.length; j++) {
        // console.log('studios/'+this.studios[j].studioId+'/contractDetails.json?auth=',{contractLength:this.minLengthVal});

        axios.patch('studios/'+this.studios[j].studioId+'/contractDetails.json?auth='+this.$store.state.auth.idToken,{contractLength:this.minLengthVal})
        .then(()=>{
          this.showSnackbar=false
          console.log('Finished studios');
          this.snackbarMessage="Studios Updated!"
          this.showSnackbar=true
        })
        .catch(()=>{
          this.showErrorSnackbar=true
        })
      }
      for (var k = 0; k < this.wholeApts.length; k++) {
        // console.log('wholeApts/'+this.wholeApts[k].apartmentId+'/contractDetails.json?auth=',{contractLength:this.minLengthVal});
        axios.patch('wholeApts/'+this.wholeApts[k].apartmentId+'/contractDetails.json?auth='+this.$store.state.auth.idToken,{contractLength:this.minLengthVal})
        .then(()=>{
          this.showSnackbar=false
          this.snackbarMessage="Whole Apartments Updated!"
          this.showSnackbar=true
          console.log('Finished wholeApts');
        })
        .catch(()=>{
          this.showErrorSnackbar=true
        })
      }
      this.dialogText="Listings are being updated. This should only take a few seconds."
      this.dialogOpen=true
    }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
    contractLengthOptions: function() {
      let t = ['1 month',
        '3 months',
        '5 months',
        '12 months',
        'Open contract'
      ]
      if (this != undefined) {
        t = [{
            value: '1 month',
            text: this.$t('contractDetails.minLengthOptions.one'),
          },
          {
            value: '3 months',
            text: this.$t('contractDetails.minLengthOptions.three'),
          },
          {
            value: '5 months',
            text: this.$t('contractDetails.minLengthOptions.five'),
          },
          {
            value: '12 months',
            text: this.$t('contractDetails.minLengthOptions.twelve'),
          },
          {
            value: 'Open contract',
            text: this.$t('contractDetails.minLengthOptions.open'),
          }
        ]
      }
      return t
    },

  },

  firebase() {
    return {
      apartments: {
        source: firebase.database().ref('users/' + this.user.id + '/apartments'),
        readyCallback: function() {
          this.initialLoad = false
          console.log('sort Apartments',this.apartments);
          this.apartments.sort(this.sortListings)
          console.log('apts sorted');
        },

      },
      wholeApts: {
        source: firebase.database().ref('users/' + this.user.id + '/wholeApts'),
        readyCallback: function() {
          this.initialLoad = false
          for (var apt in this.wholeApts) {
            if (this.wholeApts.hasOwnProperty(apt)) {
              this.wholeApts[apt].type="wholeApt"
            }
            console.log();
            axios.get('/rSeen.json?orderBy="id"&equalTo="' + this.wholeApts[apt].apartmentId + '"')
            .then(res=>{
              console.log('got apts', res.data);
              this.wholeAptsViews=this.wholeAptsViews+Object.keys(res.data).length
              this.totalViews=this.totalViews+Object.keys(res.data).length
              for (var j in res.data) {
                if (res.data.hasOwnProperty(j)) {
                  if (moment(res.data[j].ts).isAfter(moment().subtract(1,'Month'))) {
                    this.monthViews++
                    if (moment(res.data[j].ts).isAfter(moment().subtract(1,'week'))) {
                      this.weekViews++
                    }
                  }
                }
              }
            })
          }
          this.wholeApts.sort(this.sortListings)

        },
      },
      studios:{
        source: firebase.database().ref('users/' + this.user.id + '/studios'),
        readyCallback: function() {
          this.initialLoad = false
          for (var i = 0; i < this.studios.length; i++) {
            console.log(this.studios[i].studioId);
            axios.get('/rSeen.json?orderBy="id"&equalTo="' + this.studios[i].studioId + '"')
            .then(res=>{
              console.log('got studios',res.data);
              this.studioViews=this.studioViews+Object.keys(res.data).length
              this.totalViews=this.totalViews+Object.keys(res.data).length
              for (var j in res.data) {
                if (res.data.hasOwnProperty(j)) {
                  console.log(moment(res.data[j].ts).isAfter(moment().subtract(1,'Month')));
                  if (moment(res.data[j].ts).isAfter(moment().subtract(1,'Month'))) {
                    this.monthViews++
                    if (moment(res.data[j].ts).isAfter(moment().subtract(1,'week'))) {
                      this.weekViews++
                    }
                  }
                }
              }
            })
          }
          this.studios.sort(this.sortListings)


        },

      }

    }
  },
  created() {

    console.log('user', this.user);

  },

  watch: {
    apartments: function() {
      this.initialLoad = false
      // console.log('val', val);
    },
    studios: function() {
      this.initialLoad = false
    }
  },
  components: {
    Loading,
    draggable,
    DashboardApartmentCard,
    DashboardStudioCard,
    DashboardWholeAptCard,
  }

}
</script>
<style lang="scss" scoped>
.landlordDashboardContainer {
    min-height: 100vh;
}
.container {
    width: 100vw;
    max-width: 1200px;
}

.pa-3.top-padding-only {
    padding-bottom: 0px!important;
}
</style>
