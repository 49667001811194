<template lang="html">
  <v-layout  wrap md9 xs11 justify-center>
    <v-flex xs12 class="pt-4 text-xs-center">
      <div class="title">
        {{$t("loading.loading")}}
      </div>
    </v-flex>
    <v-flex xs12 class="text-xs-center py-3">
      <v-progress-circular indeterminate :size="100" color="primary" :width="10"></v-progress-circular>
    </v-flex>
    <v-flex xs12 class="text-xs-center pa-2" v-if="numberOfList!=undefined">
      {{message}}
    </v-flex>
  </v-layout>

</template>

<script>
export default {

  props:['numberOfList'],

  computed:{
    message(){
      let  x = Math.floor(Math.random() * 3);
      console.log('this. is x',x);
      switch (x) {
        case 0:
          return "Browsing "+ this.numberOfList + " listings in Taipei..."
        case 1:
          return "Early bookers get cheaper prices and better listings"
        case 2:
          return "Book 2 months in advance for better choice"
        default:
          return  "Browsing "+ this.numberOfList + " listings in Taipei..."
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
